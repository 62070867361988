
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCoachDashboardRootPostsFormCreate} from "@/models/app/coach/dashboard/root/posts/form/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";
import {Plus} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    Plus,
  },
})
export default class AppCoachDashboardRootPostsFormCreateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  formData: AppCoachDashboardRootPostsFormCreate = new AppCoachDashboardRootPostsFormCreate();
  formDataRules = {
    content: {
      roles: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
      message: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/post/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getPosts');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AppCoachDashboardRootPostsFormCreate();
  }
}
