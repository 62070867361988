
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import moment from "moment";

// Store
import store from "@/store/index";

// Models
import {AppCommonNotification} from "@/models/app/common/notification";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppCoachDashboardRootNotificationsFormCreateIndexVue from "@/views/app/coach/dashboard/root/notifications/form/create.vue";
import AppCoachDashboardRootNotificationsFormUpdateIndexVue from "@/views/app/coach/dashboard/root/notifications/form/update.vue";
import AppCoachDashboardRootNotificationsFormRemoveIndexVue from "@/views/app/coach/dashboard/root/notifications/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppCoachDashboardRootNotificationsFormCreateIndexVue,
    AppCoachDashboardRootNotificationsFormUpdateIndexVue,
    AppCoachDashboardRootNotificationsFormRemoveIndexVue
  },
})
export default class AppCoachDashboardRootMatchesIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      roles: "*",
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  notifications: Array<AppCommonNotification> = [];

  async getNotifications(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/notifications`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.notifications = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    this.formData.filter.roles = store.getters.getOrganization.role;
    await this.getNotifications();
  }
}
