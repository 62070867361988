
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCoachDashboardRootPostsFormUpdate} from "@/models/app/coach/dashboard/root/posts/form/update";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElInput,
  ElButton,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "post",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    Edit,
  },
})
export default class AppCoachDashboardRootPostsFormUpdateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  post: AppCoachDashboardRootPostsFormUpdate = new AppCoachDashboardRootPostsFormUpdate();

  formData: AppCoachDashboardRootPostsFormUpdate = new AppCoachDashboardRootPostsFormUpdate();
  formDataRules = {
    content: {
      roles: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
      message: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/post/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getPosts');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.post;
  }

  resetFormData(): void {
    this.formData = new AppCoachDashboardRootPostsFormUpdate();
  }

  updated(): void {
    this.setFormData();
  }
}
