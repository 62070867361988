export interface AppCoachDashboardRootPostsFormRemove {
  id: string;
  user_id: string;
  organization_id: string;
  user: {
    id: string;
    content: {
      name: string;
      surname: string;
    };
  };
  content: {
    roles: string;
    message: string;
    url: string;
  };
}

export class AppCoachDashboardRootPostsFormRemove implements AppCoachDashboardRootPostsFormRemove {
  id: string;
  user_id: string;
  organization_id: string;
  user: {
    id: string;
    content: {
      name: string;
      surname: string;
    };
  };
  content: {
    roles: string;
    message: string;
    url: string;
  };

  constructor() {
    this.id = "";
    this.user_id = "";
    this.organization_id = "";
    this.user = {
      id: "",
      content: {
        name: "",
        surname: "",
      },
    };
    this.content = {
      roles: "",
      message: "",
      url: "",
    };
  }
}