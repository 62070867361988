// Store
import store from "@/store/index";

export interface AppCoachDashboardRootPostsFormCreate {
  user_id: string;
  organization_id: string;
  user: {
    id: string;
    content: {
      name: string;
      surname: string;
    };
  };
  content: {
    roles: string;
    message: string;
    url: string;
  };
}

export class AppCoachDashboardRootPostsFormCreate implements AppCoachDashboardRootPostsFormCreate {
  user_id: string;
  organization_id: string;
  user: {
    id: string;
    content: {
      name: string;
      surname: string;
    };
  };
  content: {
    roles: string;
    message: string;
    url: string;
  };

  constructor() {
    this.user_id = store.getters.getAuthentication.id ?? "";
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.user = {
      id: "",
      content: {
        name: "",
        surname: "",
      },
    };
    this.content = {
      roles: "",
      message: "",
      url: "",
    };
  }
}