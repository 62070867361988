
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachDashboardNavigationVue from "@/components/coach/dashboard/navigation.vue";
import AppCoachDashboardRootMatchesIndexVue from "@/views/app/coach/dashboard/root/matches/index.vue";
import AppCoachDashboardRootTrainingsIndexVue from "@/views/app/coach/dashboard/root/trainings/index.vue";
import AppCoachDashboardRootNotificationsIndexVue from "@/views/app/coach/dashboard/root/notifications/index.vue";
import AppCoachDashboardRootPostsIndexVue from "@/views/app/coach/dashboard/root/posts/index.vue";
import AppCoachDashboardRootUserInformationIndexVue from "@/views/app/coach/dashboard/root/user-information/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachDashboardNavigationVue,
    AppCoachDashboardRootMatchesIndexVue,
    AppCoachDashboardRootTrainingsIndexVue,
    AppCoachDashboardRootNotificationsIndexVue,
    AppCoachDashboardRootPostsIndexVue,
    AppCoachDashboardRootUserInformationIndexVue,
  },
})
export default class AppCoachDashboardRootIndexVue extends Vue {}
